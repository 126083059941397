import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import { customEmailTemplateService, emailService, projectsService } from '../../../main';
import EmailActionParameters, { EmailRecipient, EmailType } from './emailActionParams';
import EmailTemplate from '../../../modules/emailTemplates/models/emailTemplate';
import { EmailScheduleService } from '@/services/emailScheduleService';
import { Project } from '@/models/Project';
import { email } from 'vuelidate/lib/validators';
import ProjectParticipant from '@/modules/participants/models/ProjectParticipant';
import { BModal } from 'bootstrap-vue';
import to from 'await-to-js';

@Component({
    components: {
        'email-edit': require('../../email-edit/emailEdit.vue').default,
        'email-preview': require('../../email-edit/emailPreview.vue').default,
        'datetime-input': require('../../inputs/dateTimeInput.vue').default,
    },
    validations: {
        cc: {
            email,
        },
        bcc: {
            email,
        },
    },
})
export default class EmailActionComponent extends SideActionBase<EmailActionParameters<any>> {
    public emailTemplates: EmailTemplate[] = [];
    public baseTemplates: EmailTemplate[] = [];
    public cc: string = '';
    public bcc: string = '';
    public isLoaded: boolean = false;
    public emailType: EmailType = this.options.emailType;
    public manualSelected: boolean = this.options.manualSelected;
    public progress: number = 0;
    public timeZone: string = this.options.timeZone;

    public emailParticipants: any[] = [];
    public projectData: Project = null;
    public tabIndex: number = 0;

    public scheduledDate: Date = new Date();
    public emailScheduleService: EmailScheduleService = new EmailScheduleService();

    public $refs!: {
        progressModal: BModal;
    };

    get recipients(): any[] {
        return this.options.recipients.slice(0, this.options.recipients.length > 100 ? 100 : this.options.recipients.length).map((r) => {
            return {
                name: r.firstName + ' ' + r.lastName,
                firstName: r.firstName,
                lastName: r.lastName,
                emailAddress: r.emailAddress,
                preferredLanguage: r.preferredLanguage,
                survey: r.survey,
                data: r.data,
            };
        });
    }

    get languages(): string[] {
        const languages = this.options.recipients
            .map((r) => {
                return r.preferredLanguage;
            })
            .filter((languageId) => {
                return languageId !== null;
            });

        return languages.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
    }

    public async created() {
        const templates = await emailService.getTemplates(`${this.options.knowledgeModelAlias}_${this.options.template}`, this.languages);

        if (this.options.projectId) {
            this.projectData = await projectsService.getProject(this.options.projectId);

            const customTemplates = await customEmailTemplateService.getTemplates(this.options.projectId, this.options.template, this.languages);
            this.baseTemplates.push(...templates);

            const emailTemplates = templates.map((value) => {
                const customTemplate = customTemplates.find((x) => x.language === value.language);
                if (customTemplate && customTemplate.body && customTemplate.subject) {
                    return customTemplate;
                } else {
                    return this.baseTemplates.find((baseTemp) => {
                        return baseTemp.language === value.language;
                    });
                }
            });

            this.emailTemplates = emailTemplates;
        } else {
            this.emailTemplates = templates;
        }

        this.isLoaded = true;
        if (this.$store.getters['participantsStore/showInviteAction']) {
            this.$store.commit('participantsStore/SET_INVITE_ACTION', false);
        }
    }

    public async restoreCustomTemplateToDefault(): Promise<void> {
        this.emailTemplates = this.baseTemplates;
    }

    public getRandomEmailParticipant(): ProjectParticipant {
        return this.recipients[Math.floor(Math.random() * this.recipients.length)].data;
    }

    public async updateTemplate(templates: EmailTemplate[]) {
        if (this.options.projectId) {
            await customEmailTemplateService.upsertTemplates(this.options.projectId, this.options.template, templates);
        }
    }

    public async scheduleEmails(): Promise<void> {
        this.$refs.progressModal.show();

        let errCount = 0;

        for (let i = 0; i < this.options.recipients.length; i++) {
            const recipient = this.options.recipients[i];

            if (this.options.recipients.length < 10) {
                await this.sleep(200);
            }

            const [err, success] = await to(this.scheduleEmail(recipient));
            if (err || !success) {
                errCount++;
                console.error('An error occurred', err);
            }

            this.progress++;
        }
        this.$refs.progressModal.hide();

        if (!errCount) {
            this.finished();
        } else {
            this.showError(`${errCount} email ${errCount > 1 ? 's' : ''} could not be scheduled`);
        }
    }

    public async scheduleEmail(recipient: EmailRecipient<any>): Promise<boolean> {
        const template = this.getTemplateByLanguage(this.emailTemplates, recipient.preferredLanguage);
        if (template) {
            const [err] = await to(
                this.emailScheduleService.scheduleProjectEmail({
                    projectId: this.options.projectId,
                    participantId: recipient.data.participantId,
                    cc: this.cc,
                    bcc: this.bcc,
                    scheduledAt: this.scheduledDate,
                    template: this.options.template,
                }),
            );

            if (err) {
                this.showError('Email could not be scheduled');
                return false;
            }

            return true;
        } else {
            this.showError('Email could not be scheduled');
            throw new Error('No template found for recipient: ' + recipient.firstName);
        }
    }

    public removeRecipient(recipient) {
        this.options.recipients = this.options.recipients.filter((x) => x.emailAddress !== recipient.emailAddress);
    }

    public getPlaceHolderReplacers(): any[] {
        let replacers: any[];
        replacers = this.options.recipients.map((r) => {
            return r.data;
        });
        for (let i = 0; i < replacers.length; i++) {
            replacers[i].id = i;
        }

        return replacers;
    }

    public switchTemplate(value: any) {
        this.tabIndex = value;
    }

    public getBodyOfEmailTemplate() {
        return this.emailTemplates[this.tabIndex] !== null ? this.emailTemplates[this.tabIndex].body : '';
    }

    public getEmailTemplate(): string {
        const template = this.getEmailTemplateObject();

        if (!template) {
            return '';
        }

        return template.body;
    }

    public getActiveLanguage(): string {
        const template = this.getEmailTemplateObject();

        if (!template) {
            return '';
        }

        return template.language;
    }

    private getTemplateByLanguage(templates: EmailTemplate[], language: string): EmailTemplate {
        return templates.find((t) => {
            return t.language === language;
        });
    }

    private getEmailTemplateObject(): EmailTemplate {
        return this.emailTemplates[this.tabIndex];
    }

    private sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
}
