import { Component, Prop } from 'vue-property-decorator';
import BasePage from '@/models/BasePage';
import EmailTemplate from '@/modules/emailTemplates/models/emailTemplate';
import { Project, ProjectSchedule, ProjectUpdate, ReminderOffset } from '@/models/Project';
import { $router, customEmailTemplateService, emailService, languageService, projectsService } from '@/main';
import { ImportProjectDemographicDataOptions } from '../side-actions/import-demographic-data-on-project';
import ProjectParticipant from '@/modules/participants/models/ProjectParticipant';
import { ProjectParticipantFilter } from '@/modules/participants/models/ProjectParticipantFilter';
import to from 'await-to-js';
@Component({
    components: {
        'email-edit': require('@/components/email-edit/emailEdit.vue').default,
    },
})
export default class ProjectSettingsComponent extends BasePage {
    @Prop({ default: () => new Project() }) public project: Project;
    public surveys: ProjectParticipant[] = [];
    public customTemplates: EmailTemplate[] = [];
    public customReportTemplates: EmailTemplate[] = [];
    public baseTemplates: EmailTemplate[] = [];
    public baseReportTemplates: EmailTemplate[] = [];

    public reminderOffsets: string[] = ['beforeCloseDate', 'afterSurveyCreateDate'];

    public customControls: any = [
        ['bold', 'italic', 'underline'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
    ];

    public reminderTemplates = [
        {
            template: 'survey_reminder',
            name: 'Survey Reminder',
        },
        {
            template: 'final_survey_reminder',
            name: 'Final Survey Reminder',
        },
    ];

    public schedule = { selectedTemplate: null, offsetDays: null, reminderOffset: null, template: '' };

    public settingsEditMode: boolean = false;
    public textsEditMode: boolean = false;
    public demographicsEnabled: boolean = false;

    public get timezones() {
        return (this as any).$moment.tz.names();
    }

    public get isBigProject() {
        return this.project.participantCount > 1000;
    }

    public get projectHasSurveys() {
        return this.surveys.length > 0;
    }

    public get isAnonymousLinkProject() {
        return this.project && this.project.anonymousSurveySettings != null;
    }

    public get hasExistingTemplate() {
        return !!this.project.schedule.find((x) => !!this.schedule.selectedTemplate && x.template === this.schedule.selectedTemplate.template);
    }

    public get languageCodes(): string[] {
        const languageCodes = [];
        this.surveys.forEach((survey) => {
            if (survey.preferredLanguage && languageCodes.indexOf(survey.preferredLanguage) === -1) {
                languageCodes.push(survey.preferredLanguage);
            }
        });

        if (languageCodes.length === 0) {
            languageCodes.push('en');
        }

        return languageCodes;
    }

    public get hasDemographicQuestionsAllowed() {
        if (!this.project || !this.project.instruments) {
            return false;
        }

        return this.project.instruments.some((x) => x.demographicQuestionsAllowed);
    }

    public get hasAllAnonymousInstruments() {
        if (!this.project || !this.project.instruments) {
            return false;
        }

        return this.project.instruments.every((x) => x.isAnonymous);
    }

    public get hasAnonymousInstrument() {
        if (!this.project || !this.project.instruments) {
            return false;
        }

        return !!this.project.instruments.find((x) => x.isAnonymous);
    }

    public get hasScheduledEmails() {
        return this.surveys.some((x) => x.scheduledEmails.length > 0);
    }

    public created() {
        this.demographicsEnabled = this.project.demographicsEnabled;
    }

    public async mounted() {
        await this.loadLanguages();
        await this.initTexts();

        this.isLoaded = true;
    }

    public async initTexts() {
        this.languageCodes.forEach((languageCode: string) => {
            if (!this.project.welcomeTexts.find((x) => x.languageCode.toLowerCase() === languageCode.toLowerCase())) {
                this.project.welcomeTexts.push({
                    languageCode: languageCode,
                    text: '',
                });
            }

            if (!this.project.thankYouTexts.find((x) => x.languageCode.toLowerCase() === languageCode.toLowerCase())) {
                this.project.thankYouTexts.push({
                    languageCode: languageCode,
                    text: '',
                });
            }
        });
    }

    public getNiceLanguageName(languageCode: string) {
        const languages = languageService.getLanguages();
        const language = languages.find((x) => x.languageCode.toLowerCase() === languageCode.toLowerCase());

        return language ? language.languageName : languageCode;
    }

    public startSettingsEdit(): void {
        this.settingsEditMode = true;
    }

    public cancelSettingsEdit(): void {
        this.settingsEditMode = false;
        this.demographicsEnabled = this.project.demographicsEnabled;
        this.editDemographicsEnabled();
    }

    public async loadLanguages() {
        const [err, response] = await to(
            projectsService.getParticipants(
                new ProjectParticipantFilter({
                    projectId: this.project.projectId,
                    skip: 0,
                    take: 99,
                    $count: true,
                }),
            ),
        );

        if (err) {
            this.showError('Failed to load participants');
            return [];
        }

        this.surveys = response.items.map((x) => new ProjectParticipant(x));
    }

    public startTextEdit(): void {
        this.textsEditMode = true;
    }

    public async cancelTextsEdit() {
        this.textsEditMode = false;
    }

    public async saveTextsEdit(): Promise<void> {
        this.textsEditMode = false;
        await this.saveSettingsEdit();
    }

    public editDemographicsEnabled() {
        if (this.demographicsEnabled) {
            this.project.combineInstrumentSurveys = true;
        } else {
            this.project.combineInstrumentSurveys = false;
        }
    }

    public async saveSettingsEdit(): Promise<void> {
        if (this.hasScheduledEmails && this.demographicsEnabled && this.project.combineInstrumentSurveys) {
            if (confirm('Are you sure you want to enable demographics with combined surveys on? This will cause problems with the links in the emails that have been scheduled! When you send a new invitation, the project will work like you’re used too')) {
                await this.handleSaveSettingsEdit();
            } else {
                return;
            }
        } else {
            await this.handleSaveSettingsEdit();
        }
    }

    public addScheduleItem() {
        this.project.schedule.push(
            new ProjectSchedule({
                offsetDays: this.schedule.offsetDays,
                reminderOffset: this.schedule.reminderOffset,
                template: this.schedule.selectedTemplate.template,
            }),
        );

        this.schedule = { selectedTemplate: null, offsetDays: null, reminderOffset: null, template: '' };
    }

    public removeScheduleItem(index: number) {
        this.project.schedule.splice(index, 1);
    }

    public async upsertCustomTemplates(): Promise<void> {
        const templateString = `report_link`;
        this.showPending('Loading...');
        await customEmailTemplateService
            .upsertTemplates(this.project.projectId, templateString, this.customReportTemplates)
            .then(() => {
                this.clearAndShowSuccess('The templates are successfully saved');
            })
            .catch((e) => {
                this.clearAndShowError(`Templates couldn't be saved`, e);
            });
    }

    public async loadAllParticipants() {
        const [err, response] = await to(
            projectsService.getParticipants(
                new ProjectParticipantFilter({
                    projectId: this.project.projectId,
                    skip: 0,
                    take: this.project.participantCount,
                    $count: false,
                }),
            ),
        );

        if (err) {
            this.clearAndShowError('Failed to load participants for export');
            return [];
        }

        return response.items.map((x) => new ProjectParticipant(x));
    }

    public getOffsetLabel(offset: string) {
        switch (offset) {
            case 'beforeCloseDate':
                return 'before close date';
            case 'afterSurveyCreateDate':
                return 'after survey create date';
            default:
                return offset;
        }
    }

    public editMailContentSideAction(e: Event = null, template: string) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        this.$sideActions.push(
            'edit-project-mail-action',
            {
                projectId: this.project.projectId,
                knowledgeModelAlias: this.project.knowledgeModel.alias,
                template: template,
                languageCodes: this.languageCodes,
            },
            () => {},
        );
    }

    public async uploadProjectDemographicDataSideAction() {
        let participants = [];

        if (!this.isBigProject) {
            participants = await this.loadAllParticipants();
        }

        const options: ImportProjectDemographicDataOptions = {
            projectId: this.project.projectId,
            organizationId: this.project.organizationId,
            participants: participants.filter((x) => !!x.emailAddress),
        };

        this.$sideActions.push('import-demographic-data-on-project-action', options, () => {});
    }

    private async handleSaveSettingsEdit(): Promise<void> {
        if (this.schedule && this.schedule.selectedTemplate && this.schedule.reminderOffset && this.schedule.offsetDays) {
            this.addScheduleItem();
        }

        const projectUpdate = new ProjectUpdate(this.project);

        projectUpdate.demographicsEnabled = this.demographicsEnabled;
        this.project.demographicsEnabled = this.demographicsEnabled;

        try {
            const [err] = await to(projectsService.updateProject(this.project.projectId, projectUpdate));
            if (err) {
                throw err;
            }

            this.showSuccess('Project settings are successfully saved');
        } catch (exception) {
            this.showError('The project could not be saved due to a error');
        }

        this.settingsEditMode = false;
    }
}
