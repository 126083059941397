import { Component } from 'vue-property-decorator';
import SideActionBase from '../../../plugins/sideActions/sideActionBase';
import to from 'await-to-js';
import { customEmailTemplateService, emailService } from '@/main';
import EmailTemplate from '@/modules/emailTemplates/models/emailTemplate';

@Component({
    components: {
        'email-edit': require('@/components/email-edit/emailEdit.vue').default,
    },
})
export default class EditProjectMailAction extends SideActionBase<any> {
    public submitted: boolean = false;
    public languageCodes: string[] = [];
    public email: string = '';
    public template: string = '';
    public projectId: number = null;
    public knowledgeModelAlias: string = null;
    public isLoaded: boolean = false;
    public baseTemplates: EmailTemplate[] = [];
    public customTemplates: EmailTemplate[] = [];

    public placeholderObjects = {
        report_link: {
            projectName: ' - The name of this project',
            fullName: ' - The full name of the participant',
            firstName: ' - The first name of the participant',
            lastName: ' - The last name of the participant',
            practitionerFirstName: ' - The first name of the practitioner',
            practitionerLastName: ' - The last name of the practitioner',
            practitionerFullName: ' - The full name of the practitioner',
            reportLink: ' - The link to the report',
            context: ' - The context of the project (if any)',
        },
        invite: {
            projectName: ' - The name of this project',
            firstName: ' - The first name of the participant',
            lastName: ' - The last name of the participant',
            fullName: ' - The full name of the participant',
            surveyLink: ' - The link the survey',
            practitionerFirstName: ' - The first name of the practitioner',
            practitionerLastName: ' - The last name of the practitioner',
            practitionerFullName: ' - The full name of the practitioner',
            deadline: ' - The closing date of the project',
            context: ' - The context of the project (if any)',
        },
        survey_reminder: {
            projectName: ' - The name of this project',
            fullName: ' - The full name of the participant',
            firstName: ' - The first name of the participant',
            lastName: ' - The last name of the participant',
            practitionerFirstName: ' - The first name of the practitioner',
            practitionerLastName: ' - The last name of the practitioner',
            practitionerFullName: ' - The full name of the practitioner',
            surveyLink: ' - The link the survey',
            deadline: ' - The closing date of the project',
            context: ' - The context of the project (if any)',
        },
        final_survey_reminder: {
            projectName: ' - The name of this project',
            fullName: ' - The full name of the participant',
            firstName: ' - The first name of the participant',
            lastName: ' - The last name of the participant',
            practitionerFirstName: ' - The first name of the practitioner',
            practitionerLastName: ' - The last name of the practitioner',
            practitionerFullName: ' - The full name of the practitioner',
            surveyLink: ' - The link the survey',
            deadline: ' - The closing date of the project',
            context: ' - The context of the project (if any)',
        },
    };

    public get placeholderObject() {
        return this.placeholderObjects[this.template];
    }

    public async mounted() {
        this.languageCodes = this.options.languageCodes;
        this.projectId = this.options.projectId;
        this.knowledgeModelAlias = this.options.knowledgeModelAlias;
        this.template = this.options.template;

        await this.getCustomTemplates();

        this.isLoaded = true;
    }

    public async saveEmailEdit() {
        this.showPending('Saving templates');
        await customEmailTemplateService
            .upsertTemplates(this.projectId, this.template, this.customTemplates)
            .then(() => {
                this.clearAndShowSuccess('The templates are successfully saved');
                this.finished();
            })
            .catch((e) => {
                this.clearAndShowError(`Templates couldn't be saved`, e);
            });
    }

    public async getCustomTemplates(): Promise<void> {
        const templateString = this.template;
        if (!this.languageCodes || !this.languageCodes.length) {
            return this.showWarning(`Cannot load ${templateString} email without any project participants`);
        }

        const templates = await emailService.getTemplates(`${this.knowledgeModelAlias}_${templateString}`, this.languageCodes);
        this.customTemplates = [];
        this.baseTemplates.push(...templates);

        const customTemplates = await customEmailTemplateService.getTemplates(this.projectId, templateString, this.languageCodes);
        const emailTemplates = templates.map((value) => {
            const customTemplate = customTemplates.find((x) => x.language === value.language);
            if (customTemplate && customTemplate.body && customTemplate.subject) {
                return customTemplate;
            } else {
                return this.baseTemplates.find((baseTemp) => {
                    return baseTemp.language === value.language;
                });
            }
        });

        if (emailTemplates && emailTemplates.length) {
            this.customTemplates.push(...emailTemplates);
        }
    }

    public async restoreCustomTemplateToDefault(): Promise<void> {
        this.customTemplates = this.baseTemplates;
    }
}
